import { Typography } from '@mui/material';
import React from 'react';

// this needs to be changed to something configurable
import logo from '../../../res/ECI_Logo_Full_Official_COLOR-01.png';

/**
 * The default landing page for EFE. Is displayed when the user does not have
 * permission to access any other apps.
 * @param props
 * @returns React component
 */
export default function LandingPage () {
    return <>
        <div className="row col-1-auto landing-page">
            <div className="landing-center">
                <img src={logo} alt="ECI Logo" />
            </div>
            <div className="landing-text">
                <Typography variant="body1" align="center">
                    If you are seeing this page please wait for the application to finish loading
                    or contact your system administrator to grant you access.
                </Typography>
            </div>
        </div>
    </>;
}
