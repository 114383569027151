import { AppResponseContextInstance } from '../efe-client-library';
import { ServiceUtilities } from './ServiceUtilities';

export class UserProfileUtilities {
    /**
     * This is a function for getting the gluu user information for the user profile
     * @returns current user information found in Gluu
     */
    static async getCurrentUserInfo (context: AppResponseContextInstance): Promise<Response> {
        const userInfoResponse = await ServiceUtilities.post(
            context,
            '/iam-service/userinfo',
            undefined,
            'application/json');

        return userInfoResponse;
    }
}
