import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './override.css';
import Home from './Home';

ReactDOM.render(

    <Home />,
    document.getElementsByClassName('Root')[0]
);
