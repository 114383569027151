
/**
 * Defines an instance of the App's response context for use as a singleton.
 */
export class AppResponseContextInstance {
    constructor (responseInstance?: Response | undefined) {
        this.responseInstance = responseInstance;
    }

    responseInstance: Response | undefined;
    defaultCallback: any;
}
