import { toast } from 'react-toastify';

export class EfeToast {
    /**
     * Sets up the toast configuration for when it is lazy mounted.
     * This fixes issues with toasts not showing when npm linking Client-Library
     * to EnterpriseFrontEnd
     * https://fkhadra.github.io/react-toastify/lazy-container-and-multi-container
     */
    static initialize () {
        toast.configure();
    }

    /**
     * Creates a new default toast
     * @param message The toast's message
     * @param options The toast's options
     *
     * Options properties:
     *
     * https://fkhadra.github.io/react-toastify/introduction
     * - className: string
     * - onOpen: function
     * - onClose: function
     * - style: React.CSSProperties
     * - type: string: "info", "success", "warning", "error", "default", "dark"
     * - toastId: string or number
     * - updateId: string or number
     * - progress: string or number
     * - delay: number
     */
    static defaultToast (message: string, options: any = undefined): any {
        return toast(message, options);
    }

    /**
     * Creates a new success toast
     * @param message The toast's message
     * @param options The toast's options
     *
     * Options properties:
     *
     * https://fkhadra.github.io/react-toastify/introduction
     * - className: string
     * - onOpen: function
     * - onClose: function
     * - style: React.CSSProperties
     * - type: string: "info", "success", "warning", "error", "default", "dark"
     * - toastId: string or number
     * - updateId: string or number
     * - progress: string or number
     * - delay: number
     */
    static successToast (message: string, options: any = undefined): any {
        return toast.success(message, options);
    }

    /**
     * Creates a new warning toast
     * @param message The toast's message
     * @param options The toast's options
     *
     * Options properties:
     *
     * https://fkhadra.github.io/react-toastify/introduction
     * - className: string
     * - onOpen: function
     * - onClose: function
     * - style: React.CSSProperties
     * - type: string: "info", "success", "warning", "error", "default", "dark"
     * - toastId: string or number
     * - updateId: string or number
     * - progress: string or number
     * - delay: number
     */
    static warningToast (message: string, options: any = undefined): any {
        return toast.warning(message, options);
    }

    /**
     * Creates a new error toast
     * @param message The toast's message
     * @param options The toast's options
     *
     * Options properties:
     *
     * https://fkhadra.github.io/react-toastify/introduction
     * - className: string
     * - onOpen: function
     * - onClose: function
     * - style: React.CSSProperties
     * - type: string: "info", "success", "warning", "error", "default", "dark"
     * - toastId: string or number
     * - updateId: string or number
     * - progress: string or number
     * - delay: number
     */
    static errorToast (message: string, options: any = undefined): any {
        return toast.error(message, options);
    }

    /**
     * Creates a new info toast
     * @param message The toast's message
     * @param options The toast's options
     *
     * Options properties:
     *
     * https://fkhadra.github.io/react-toastify/introduction
     * - className: string
     * - onOpen: function
     * - onClose: function
     * - style: React.CSSProperties
     * - type: string: "info", "success", "warning", "error", "default", "dark"
     * - toastId: string or number
     * - updateId: string or number
     * - progress: string or number
     * - delay: number
     */
    static infoToast (message: string, options: any = undefined): any {
        return toast.info(message, options);
    }
}
